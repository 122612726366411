.header {
  width: 100%;
  height: 97px;
}

.header .help {
  margin-top: 28px;
  border-radius: 26.5px;
  background: #77b200 url("/assets/images/questionMark.png") no-repeat 13px;
  width: 38px;
  height: 38px;
  float: left;
  cursor: pointer;
}

.header .user {
  border-radius: 26.5px;
  background: #083b6e;
  color: white;
  float: right;
  min-width: 170px;
  text-align: center;
  margin: 20px;
}

.header .user .name {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}

.header .links a {
  color: white;
}

.header nav {
  flex-grow: 1.3;
  color: $white;
  font-size: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
      border-bottom: $cf-green solid 3px;
      margin-bottom: -3px;
    }
    &.active {
      text-decoration: none;
      border-bottom: $cf-green solid 3px;
      margin-bottom: -3px;
    }
  }
}

.header .links a:hover {
  color: white;
  text-decoration: none;
}
