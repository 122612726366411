.loginContainer {
  background: white;
  width: 453px;
  height: 457px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.loginContainer input {
  background-color: #f1f1f1;
  border-radius: 5px;
  width: 375px;
  height: 50px;
  margin: 20px auto;
}

.loginButton {
  display: block;
  background-color: #77b200;
  border-radius: 5px;
  width: 375px;
  height: 50px;
  margin: 30px auto;
  color: white;
}

.loginContainer .logo {
  background-color: #0b4a88 !important;
  height: 88px;
  width: 200px;
  margin-top: 40px;
  margin-left: 45px;
}

.loginContainer .healthCheck {
  background-color: #0b4a88 !important;
  height: 88px;
  width: 150px;
}
