body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #000;
  background: #0c4a88;
}

.loading-spinner-container {
  width: 100%;
  background-color: #f1f1f1;
  min-height: 600px;
}

.loading-spinner {
  text-align: center;
  width: 100%;
  margin-top: 32px;
  color: $cf-green;
}

input,
textarea {
  border: none;
  border-bottom: 1px solid #e6e6e6;
  padding: 9px 13px 10px;
  font-size: 15px;
  background-color: #fff;
  resize: none;
  width: 100%;
}

form label {
  display: block;
  margin: 18px 0 5px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
}

p {
  margin-bottom: 0.8rem;
}

h1 {
  font-weight: 400;
}

ul {
  list-style: none;
  padding: 0px;
}

i {
  height: 24px;
  width: 24px;
  display: block;
  float: right;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.noMargin {
  margin: 0px;
}

.pass {
  background-color: #7ed321;
  border-radius: 100%;
  width: 17px;
  height: 17px;
}

.incomplete {
  background-color: $md-gray;
  border-radius: 100%;
  width: 17px;
  height: 17px;
}

.moderate {
  height: 17px;
  width: 17px;
  background: url("/assets/images/warning.png") no-repeat;
  background-size: 17px 17px;
}

.moderate-completed {
  height: 17px;
  width: 17px;
  background: url("/assets/images/greenWarning.png") no-repeat;
  background-size: 17px 17px;
}

.fail {
  background-color: #d0021b;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

.fail-completed {
  background-color: #7ed321;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

button {
  cursor: pointer;
}

button {
  background-color: #2668b6;
  border-radius: 19px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  width: 141px;
  height: 38px;
  border: none;
}

button:disabled {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

label {
  text-align: left;
  width: 170px;
}

input[type="file"] {
  visibility: hidden;
  position: absolute;
}

.container-fluid {
  padding: 0px !important;
}

.flex {
  display: flex;
}

.col-4 {
  text-align: left;
  -webkit-flex: 0 0 35.333333%;
  flex: 0 0 35.333333%;
  max-width: 35.333333%;
}

.check {
  text-align: center;
}

.background {
  background: url("/assets/images/background.png") no-repeat bottom left;
  height: 766px;
  width: 795px;
  position: fixed;
  bottom: -98px;
  left: 0;
  z-index: -1;
}

.logo {
  height: 68px;
  width: 120px;
  background: #083b6e url("/assets/images/logo.png") no-repeat center;
  margin-top: 13px;
  margin-left: 13px;
  display: inline-block;
}

.healthCheck {
  height: 68px;
  width: 120px;
  background: #083b6e url("/assets/images/healthCheck.png") no-repeat center;
  margin-left: 5px;
  display: inline-block;
}

.healthCheckLogo {
  height: 38px;
  width: 38px;
  background: url("/assets/images/healthCheck.png") no-repeat;
  background-size: 38px 38px;
  margin-left: 20px;
}

.rectangle {
  float: left;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  width: 164px;
  height: 100%;
  min-height: 755px;
  padding-top: 17px;
  margin-top: -5px;
}

.react-container {
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.hc {
  &.form-group {
    padding: 10px 15px;
    label {
      margin-bottom: 0;
      color: $gray;
      font-size: 18px;
    }
    input {
      font-family: Arial, FontAwesome;
      background-color: #f1f1f1;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &.btn {
    width: 100%;
    border-radius: 5px;
    color: $white;
    background-color: $cf-btn-blue;
    text-align: center;
    margin-bottom: 10px;
    &.alt {
      border-color: $cf-btn-blue;
      color: $cf-btn-blue;
      background-color: $white;
      transition: all 350ms ease;
      &:hover {
        color: $white;
        background-color: $cf-btn-blue;
      }
    }
    &.disabled {
      cursor: no-drop;
    }
  }
  .multi-select {
    div {
      margin: 0.5rem;
      padding: 0.5rem;
      background-color: #efefef;
      font-size: 14px;
      display: flex;
      border-radius: 5px;
      i {
        padding-top: 2px;
      }
      &:hover {
        opacity: 0.85;
      }
    }
  }
}

.react-datepicker {
  z-index: 10;
}

.react-datepicker__time-container {
  width: 68px;
}

.react-datepicker__time-list {
  padding: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  display: block !important;
}

#slider {
  background-color: $black-30;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 10;

  & #slider-content {
    position: relative;
    max-width: 480px;
    margin-left: auto;
    padding: 0;
    background-color: $white;

    & > * {
      padding: 24px 32px;
    }

    hr {
      margin: 16px 0;
    }
  }

  .slider-title {
    max-height: 16px;
    background-color: $cf-green;
    color: $white;
    font-size: 20px;
    font-weight: bold;

    .close {
      float: right;
      cursor: pointer;
    }
  }

  .slider-content {
    height: calc(100vh - 112px);
    overflow-y: auto;
  }

  .close-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
  }
}

.slider-enter {
  //opacity: 0.01;
  & #slider-content {
    right: -480px;
  }
}
.slider-enter.slider-enter-active {
  //opacity: 1;
  //transition: opacity 150ms ease-out;
  & #slider-content {
    right: 0;
    transition: right 150ms ease-out;
  }
}
.slider-leave {
  //opacity: 1;
  & #slider-content {
    right: 0;
  }
}
.slider-leave.slider-leave-active {
  //opacity: 0.01;
  //transition: opacity 150ms ease-in;
  & #slider-content {
    right: -480px;
    transition: right 150ms ease-in;
  }
}

.toggleContainer {
  position: relative;
  top: -8px;
  left: 23px;
}

.toggleButton {
  background: transparent !important;
  border: 1px solid #4295c5;
  border-radius: 5px 0 0 5px !important;
  color: #4295c5 !important;
  font-size: 19px;
  font-weight: 400;
  margin: 0px !important;
  float: none !important;
}

.toggleView {
  background: transparent !important;
  border: 1px solid #4295c5;
  border-radius: 0 5px 5px 0 !important;
  color: #4295c5 !important;
  margin: 0px !important;
  font-size: 19px;
  font-weight: 400;
  float: none !important;
}

.toggleButton.selected,
.toggleView.selected {
  background-color: #4295c5 !important;
  color: white !important;
}
