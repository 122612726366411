@import "variables";
@import "shared";
@import "chatBot";
@import "assessmentLogs";
@import "dashboard";
@import "employee";
@import "header";
@import "login";
@import "clinics";
@import "reports";

.menuItem {
    display: block;
    width: 164px;
    height: 40px;
    padding-top: 10px;
    text-align: left;
  }
  
  .menuItem.selected {
    background-color: #3B82D7 !important;
  }
  
  .menuItem.selected p {
    color: white !important;
  }
  
  .menuItem:hover {
    text-decoration: none;
  }
  
  .menuItem p {
    color: #515151;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding-left: 24px;
  }
  
  
  /* Welcome */
  
  .welcome {
    color: #0B4A88;
    font-size: 50px;
    font-weight: 300;
    line-height: 60px;
    text-align: left;
  }
  
  .welcomeContainer .close {
    height: 32px;
    width: 32px;
    background: url("/assets/images/close.png") no-repeat;
    background-size: 32px 32px;
    float: right;
    top: 60px;
    right: 80px;
    position: absolute;
    border: none;
    padding: 15px;
    cursor: pointer;
  }
  
  .welcomeContainer .title {
    color: #4A4A4A;
    font-size: 30px;
    font-weight: 300;
    line-height: 36px;
    text-align: left;
  }
  
  .welcomeContainer .content {
    color: #5C5D5D;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
  }
  
  .welcomeContainer .iconExample {
    background-color: #FFFFFF;
    border: 1px solid #B4B4B4;
    border-radius: 8px;
    width: 100%;
    height: 49px;
    padding: 14px 50px;
    display: block;
    color: #4A4A4A;
    font-size: 17px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    margin: 20px 0px;
  }
  
  .welcomeContainer .row {
    margin: 20px 0px;
  }
  
  .welcomeContainer h2 {
    color: #0B4A88;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  
  .welcomeContainer input, .welcomeContainer select {
    background-color: #FFFFFF;
    border: 1px solid #B4B4B4;
    border-radius: 5px;
    height: 39px;
  }
  
  .welcomeContainer small {
    display: block;
    color: #5C5D5D;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    width: 257px;
    text-align: left;
  }
  
  .welcomeContainer .pass, .welcomeContainer .moderate, .welcomeContainer .fail {
    display: inline-block;
    position: relative;
    left: -25px;
  }
  
  /* Profile */
  
  .profileContainer {
    width: 100%;
    background-color: #F1F1F1;
    padding: 32px;
  }
  
  .profileContainer h1 {
    color: #0B4A88;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    text-align: left;
    padding-bottom: 8px;
  }
  
  .profileContainer h2 {
    color: #0B4A88;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  
  .profileContainer p {
    color: #666767;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  
  .profileContainer a {
    color: #0B4A88;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  
  .profileContainer select {
    background-color: #F1F1F1;
    border-radius: 5px;
    width: 292px;
    height: 39px;
  }
  
  .profileContainer input {
    background-color: #F1F1F1;
    border-radius: 5px;
    width: 292px;
    height: 39px;
    border-bottom: none;
  }
  
  .profileContainer small {
    display: block;
    color: #5C5D5D;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    width: 257px;
    text-align: left;
  }
  
  .profileContainer .row {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 20px 5px;
  }
  
  .profileContainer .buttonRow {
    margin-top: 30px;
  }

  .profileContainer .col-4{
    max-width: 33.3333%;
  }
  
  .apply {
    background-color: #4295C5;
    border-radius: 5px;
    width: 227px;
    height: 39px;
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    margin-right: 10px;
  }
  
  .clear {
    background-color: white;
    color: #4295C5;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    border: 1px solid #4295C5;
    border-radius: 5px;
    width: 227px;
    height: 39px;
  }
  