.clinicsContainer {
  width: 100%;
  background-color: #F1F1F1;
  padding: 20px;
  min-height: 650px;
}

.clinicsContainer h2 {
  color: #0B4A88;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  text-align: left;
}

.clinicsContainer button {
  color: #FFFFFF;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  background-color: #77B200;
  border-radius: 5px;
  height: 39px;
  min-width: 180px;
  float: right;
  margin-left: 5px;
}

.clinicsContainer #search input{
  font-family:Arial, FontAwesome;
  border-radius: 5px;
  width: 100%;
  margin-top: 15px;
}

.clinicsContainer #search input:focus {
  outline: none;
}

.clinicsContainer .filter {
  height: 25px;
  width: 25px;
  background: url("/assets/images/filter.png") no-repeat;
  background-size: 25px 25px;
  float: right;
  top: 6px;
  right: 2px;
  position: relative;
  border: none;
  padding: 13px;
  cursor: pointer;
}

.clinicsContainer table {
  width: 100%;
  margin: 18px 0px;
}

.clinicsContainer thead tr{
  height: 50px;
}

.clinicsContainer th, .clinicsContainer label  {
  color: #0B4A88;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.clinicsContainer tbody tr{
  background-color: white;
  height: 49px;
  margin-bottom: 5px;
  border-bottom: #F1F1F1 4px solid;
  cursor: pointer;
}

.clinicsContainer td {
  color: #5D5D5D;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 5px;
}

.clinicsContainer .oval {
  background-color: #7ED321;
  border-radius: 100%;
  width: 9px;
  height: 9px;
}

.clinicsContainer .clinics {
  min-height: 495px;
}

.clinicsContainer .clinicInformation {
  background-color: white;
  padding: 20px;
  margin-top: 10px;
}

.clinicsContainer .clinicInformation h3{
  color: #0B4A88;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.clinicsContainer .clinicInformation p{
  color: #666767;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: pre-line;
}

.clinicsContainer .clinicInformation .email{
  color: #0B4A88;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
}

.clinicsContainer .buttonRow{
  clear: both;
  width: 100%;
  margin-top: 30px;
}

.clinicsContainer .buttonRow .edit {
  float: left;
  background-color: #4295C5;
  border-radius: 5px;
  width: 292px;
  height: 39px;
  color: #FFFFFF;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

.clinicsContainer .buttonRow .delete {
  float: left;
  background-color: transparent;
  border: 1px solid #D0021B;
  border-radius: 5px;
  width: 292px;
  height: 39px;
  color: #D0021B;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

.clinicsContainer #myMap {
  overflow: hidden;
  min-height: 430px;
  margin-top: 10px;
}

.clinicsContainer .col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.clinicsContainer .mapKey {
  width: 100%;
  color: #5D5D5D;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  padding: 8px 15px;
}

.clinicsContainer .mapKeyClinic {
  background-color: #77B200;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
  float: none;
  margin-right: 7px;
}

.clinicsContainer .mapKeyStore {
  background-color: #0B4A88;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
  float: none;
  margin: 0px 7px;
}