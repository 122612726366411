@import "variables";

.chatHistory {
  width: 100%;
  height: 100%;
  background: white;
  padding-top: 1px;
  border-right: 1px solid $lt-gray;
  min-height: 540px;
  max-height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 10px;
}

.chatHistory input:focus {
  outline: none;
}
.chatHistory {
  .filter-btn {
    border-radius: 5px;
    color: #757575;
    background-color: #e5e5e5;
    font-weight: 700;
  }
}

.chatHistory .button {
  width: 90%;
  margin: 10px 15px;
  padding-left: 1rem;
  text-align: left;
  padding-top: 8px;
}

.chatHistory .plusSign {
  height: 28px;
  width: 28px;
  background: url("/assets/images/plus.png") no-repeat;
  background-size: 28px 28px;
  float: right;
  top: -4px;
  position: relative;
  border: none;
  padding: 13px;
}

.chatHistory .filter {
  height: 25px;
  width: 25px;
  background: url("/assets/images/filter.png") no-repeat;
  background-size: 25px 25px;
  float: right;
  top: 12px;
  right: 13px;
  position: relative;
  border: none;
  padding: 13px;
  cursor: pointer;
}

.chatContainer {
  width: 100%;
  background-color: #f1f1f1;
}

.chatContainer h1 {
  color: #0b4a88;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  text-align: left;
  padding: 10px 0px;
}

.chatContainer .col-3,
.chatContainer .col-9 {
  padding: 0px;
}

.chats {
  min-height: 20px;
  width: 100%;
  float: left;
}

.chat {
  background-color: #ffffff;
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  padding: 7.5px 10px;
  cursor: pointer;
  &:hover {
    background-color: $extra-lt-gray;
  }
}

.chat .row,
.chatBot .row {
  margin: 0px;
}

.chat .col-7 {
  padding: 0px;
}

.chatLight {
  align-self: center;
}

.chatLight span {
  display: block;
}

.chat .title {
  color: #5c5d5d;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  text-align: left;
}

.chat .body {
  color: #5c5d5d;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  text-align: left;
}

.chat .date {
  color: #5c5d5d;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  padding-top: 20px;
}

.chat.selected {
  background-color: #f1f1f1;
}

.chat .fa-check {
  color: green;
  position: absolute;
  top: 3px;
  right: 0px;
}

.chatDisplay {
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  width: calc(100% - 196px);
  float: left;
  min-height: 560px;
}

.topWarning {
  position: absolute;
  background: #d0021b;
  height: 40px;
  color: white;
  font-size: 16px;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

/* Chat Bot */
.chatBot {
  width: 100%;
  height: 638px !important;
}

.chatBot .wc-header {
  display: none !important;
}

.chatBot.welcomeContainer {
  padding: 20px;
  overflow: scroll;
}

.chatBot {
  /*chat transacript window*/
  .content {
    overflow: visible !important;
    .from-user {
      color: $cf-default-text-color;
      background-color: $extra-lt-gray;
      border-radius: 5px;
      font-weight: 400;
    }
    .bubble {
      overflow: visible !important;
      border: none !important;
    }
    .markdown {
      background-color: $cf-extra-dk-blue;
      color: $white;
      border-radius: 5px;
      position: relative;
      margin-left: 4em;
      :before {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: -15px;
        width: 0;
        height: 0;
        border: 15px solid $cf-extra-dk-blue;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
      :after {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: -4em;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $cf-extra-dk-blue url(/assets/images/cf-logo-sm.png) center
          no-repeat;
        background-size: 32px;
      }
      ul {
        background-color: $cf-extra-dk-blue;
      }
    }

    /*result*/
    .attachment.bubble {
      margin-left: 4em;
      div > .ac-container {
        border-top: 10px solid $yellow;
        .ac-textBlock {
          :after {
            display: none;
          }
          :before {
            display: none;
          }
          p {
            font-family: $cf-default-typeface;
            font-weight: 300;
            color: $white;
          }
          ol {
            font-family: $cf-default-typeface;
            font-weight: 300;
            color: $white;
          }
        }
        background-color: $cf-extra-dk-blue;
        color: $white;
        border-radius: 5px;
        position: relative;
        :before {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0;
          left: -15px;
          width: 0;
          height: 0;
          border: 15px solid $cf-extra-dk-blue;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
        :after {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0px;
          left: -4em;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: $cf-extra-dk-blue url(/assets/images/cf-logo-sm.png)
            center no-repeat;
          background-size: 32px;
        }
      }
    }
  }

  /*Suggested row*/
  div > div > div > div {
    ul:first-of-type:before {
      padding-left: 10px;
      display: block;
      content: "Suggested Responses:";
      color: $cf-default-text-color;
      position: absolute;
    }
  }

  div > div > div > div > ul {
    li > div > button {
      margin-top: 20px;
    }

    li > div > button:not(:disabled) {
      border-radius: 18px;
      border: none;
      height: auto;
      width: 80px;
      padding: 2.5px;
      color: $white;
      background-color: $cf-lt-blue;
      nobr {
        margin: 0 auto;
      }
    }
    background-color: $extra-lt-gray;
  }
}

.conversation {
  background-color: $white;
  width: calc(100% - 15px);
  height: 650px;
  overflow-y: scroll;
  font-size: 14px;
  .row {
    &.answer-row {
      justify-content: flex-end;
      .submitted {
        text-align: right;
      }
    }
    div {
      margin: 7.5px;
      padding: 9px;
      position: relative;
      img {
        width: 100%;
      }
      &.submitted {
        width: 100%;
        margin: 0 15px;
        padding: 0 15px;
        font-size: 12px;
        color: $lt-gray;
      }
      &.question {
        color: $white;
        margin-left: 5em;
        max-width: 500px;
        width: auto;
        background-color: $cf-extra-dk-blue;
        border-radius: 5px;
        &:before {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0;
          left: -15px;
          width: 0;
          height: 0;
          border: 15px solid $cf-extra-dk-blue;
          border-top-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
        &:after {
          content: " ";
          display: block;
          position: absolute;
          bottom: 0px;
          left: -4em;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: $cf-extra-dk-blue url(/assets/images/cf-logo-sm.png)
            center no-repeat;
          background-size: 32px;
        }
        &.final-assessment {
          &.good {
            border-top: 10px solid $cf-green;
          }
          &.restricted,
          &.restrict {
            border-top: 10px solid $yellow;
          }
          &.exclude {
            border-top: 10px solid $dk-red;
          }
        }
        li {
          margin-bottom: 10px;
        }
        li p {
          margin-bottom: 0px !important;
        }
      }
      &.answer {
        max-width: 500px;
        background-color: $extra-lt-gray;
      }
    }
  }
}
