@import "variables";

.assessmentContainer {
  width: 100%;
  background-color: #F1F1F1;
  max-height:650px;
}

.emptyAssessment {
  text-align: center;
  padding-top: 220px;
}

.startAssessmentButton {
  background-color: #77B200;
  border-radius: 5px;
  color: white;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  width: 211px;
  height: 50px;
}

.startAssessmentText {
  color: #0B4A88;
  padding: 0 1.5em;
  font-size: 50px;
  font-weight: 300;
  line-height: 60px;
}

.selectAssessmentText {
  color: #AFAFAF;
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
}

.selectedUser {
  background-color: #4295C5;
  border-radius: 5px;
  width: 90%;
  height: 80px;
  color: #FFF;
  margin: 10px 15px;
  padding: 11px 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.selectedUser .name{
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.userClose {
  height: 32px;
  width: 32px;
  background: url("/assets/images/whiteClose.png") no-repeat;
  background-size: 24px 24px;
  float: right;
  top: 38px;
  right: 35px;
  position: absolute;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.assessmentContainer .chatHistory {
  max-height:550px;
  border: none;
}

.assessmentContainer .chatHistoryContainer {
  max-height:650px;
  background: white;
  border-right: 1px solid $lt-gray;
  padding: 0 0 0 15px;
}

.assessmentContainer .chatHistoryContainer .chatHistory {
  padding: 0px !important;
}

.assessmentContainer .chatDetail {
  padding: 0px;
}

.impersonation {
  position: absolute;
  background: #d0021b;
  height: 40px;
  color: white;
  font-size: 16px;
  width: 98%;
  text-align: center;
  padding-top: 10px;
}

.impersonate {
  padding: 0px 10px;
}