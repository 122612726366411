.reportsContainer {
  width: 100%;
  background: white;
  padding: 20px;
}

.reportsContainer .export {
  color: #FFFFFF;
  height: 26px;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  width: 77.13px;
  text-align: center;
  float: right;
  background-color: #77B200;
  border-radius: 19px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.reportsContainer .export:hover {
  color: #FFFFFF;
  text-decoration: none;
}