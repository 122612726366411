// SOLID COLORS
$cf-green: #8fc539;
$cf-extra-dk-blue: #083B6D;
$cf-dk-blue: #17599c;
$cf-md-blue: #2668b6;
$cf-lt-blue: #3b82d7;
$cf-btn-blue: #4295C5;
$dk-orange: #f57423;
$dk-red: #E64839;
$yellow: #E9D700;
$green-highlight: #e5f1ce;
$dk-blue: #004b87;
$bg-gray: #F4F4F4;
$gray: #515151;
$md-gray: #737373;
$lt-gray: #b3b3b3;
$extra-lt-gray: #ededed;
$off-white: #e5e5e5;
$white: #fff;
$black: #000000;

// ALPHA COLORS
$cf-green-80: rgba(143, 197, 57, 0.8);
$cf-green-50: rgba(143, 197, 57, 0.5);
$gray-30: rgba(255, 255, 255, 0.3);
$gray-50: rgba(255, 255, 255, 0.5);
$dk-gray-20: rgba(25, 25, 25, 0.2);
$black-30: rgba(0, 0, 0, 0.3);

// TYPOGRAPHY
$cf-default-text-color: #4c4c4c;
$cf-heading-font: 'Yanone Kaffeesatz';
$cf-default-typeface: "Open Sans", sans-serif;
$default-font-weight: 400;
$heading-font-size: 24px;
$default-font-size: 13px;
$table-text-color: #191919;

// TRANSITIONS
$short-fade: 0.15s ease-out;

// DIMENSIONS
$max-width: 1234px;
$sidebar-width: 225px;
