
/* Dashboard */

.dashboardContainer{
    width: 100%;
    height: 100%;
    background-color: #F1F1F1;
    padding: 20px;
  }
  
  .dashboardContainer .layer {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0px;
  }
  
  .dashboardContainer h1 {
    color: #0B4A88;
    font-size: 40px;
    font-weight: 300;
    line-height: 48px;
    text-align: left;
    margin-top: 6px;
  }
  
  .dashboardContainer h2 {
    color: #5C5D5D;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-top: 6px;
  }
  
  .dashboardContainer h4 {
    color: #0B4A88;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
  }
  
  .dashboardContainer h5 {
    color: #5C5D5D;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  
  .dashboardContainer p {
    color: #5C5D5D;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
  }
  
  .dashboardContainer hr {
    margin: 5px 0px;
  }
  
  .dashboardContainer .exclusionName {
    color: #5C5D5D;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: block;
  }
  
  .dashboardContainer .exclusionDate {
    color: #AEAEAE;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    display: block;
  }
  
  .dashboardContainer .col-3, .dashboardContainer .col-5, .dashboardContainer .col-7, .dashboardContainer .col-9, .dashboardContainer .col-12 {
    padding: 0px 10px 0px 0px;
  }
  
  .dashboardContainer .col-4 {
    padding: 0px 5px 0px 0px;
    display: inline-block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  
  .dashboardContainer .blueBorder {
    border-top: #4295C5 5px solid;
  }
  
  .dashboardContainer .blueBorder h2 {
    text-align: center !important;
  }
  
  .dashboardContainer .value {
    color: #77B200;
    font-size: 80px;
    font-weight: 200;
    line-height: 96px;
    text-align: center;
  }
  
  .dashboardContainer .blueBorder .average {
    color: #0B4A88;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    width: 263px;
    text-align: center;
  }
  
  .dashboardContainer .overview {
    color: #AEAEAE;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
  }
  
  .dashboardContainer .flex {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 320px;
    overflow: scroll;
  }
  
  .dashboardContainer .flex div {
    width: 30%;
    border-bottom: 1px solid #E2E2E2;
    padding: 5px 0px;
    margin-right: 40px;
  }
  
  .dashboardContainer .flex .allName {
    color: #5C5D5D;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    float: left;
  }
  
  .dashboardContainer .flex .allCount {
    color: #0B4A88;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    float: right;
  }
  
  .dashboardContainer .barGraph {
    height: inherit; // suppress 'do not use empty rulesets'
  }
  
  /* Admin Dashboard*/
  
  .dashboardContainer .manageAuthorization {
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    background-color: #4295C5;
    border-radius: 5px;
    width: 100%;
    height: 39px;
  }
  
  .dashboardContainer .region {
    color: #5C5D5D;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    display: block;
    cursor: pointer;
  }
  
  .dashboardContainer .regionEmployee {
    color: #AEAEAE;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }

  .dashboardContainer .regionOverview {
    min-height: 410px;
  }

  .dashboardContainer #myMap {
    min-height: 350px;
    overflow: hidden;
  }

  .dashboardContainer .excludeList{
    max-height: 408px;
    overflow-y: scroll;
  }

  .dashboardContainer .cancel {
    float: right;
    color: white;
    margin-top: 10px;
  }

  .removeRestrictions input[type='checkbox'] {
    width: 14px;
    zoom: 1.2;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
  }

  .removeRestrictions tr {
    line-height: 40px;
    border-bottom: 1px solid #e6e6e6;
  }

  .removeRestrictions td {
    min-width: 40px;
  }

  .removeRestrictions .name {
    min-width: 130px;
  }

  .removeRestrictions .state {
    min-width: 130px;
  }

  .removeRestrictions .attestation {
    margin-top: 5px;
    border: 1px solid #e6e6e6 !important;
  }

  .ReactModal__Content .buttonRow {
    margin: 30px 0px 0px 0px;
  }

  .ReactModal__Content .buttonRow button {
    color: white;
    margin-right: 5px;
  }
  