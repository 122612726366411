
.employeesContainer {
    width: 100%;
    height: 100%;
    background: white;
    padding: 20px 0px;
  }

.employeesContainer input {
    font-family:Arial, FontAwesome;
    background-color: #F1F1F1;
    border-radius: 5px;
    margin: 5px 25px;
    width: 85%;
  }

  .employeesContainer input:focus {
    outline: none;
  }

  .employeesContainer .plusSign {
    height: 28px;
    width: 28px;
    background: url("/assets/images/plus.png") no-repeat;
    background-size: 28px 28px;
    float: right;
    top: -4px;
    position: relative;
    border: none;
    padding: 13px;
  }

  .employeesContainer .filter {
    height: 25px;
    width: 25px;
    background: url("/assets/images/filter.png") no-repeat;
    background-size: 25px 25px;
    float: right;
    top: 12px;
    right: 13px;
    position: relative;
    border: none;
    padding: 13px;
    cursor: pointer;
  }

  .employees {
    width: 100%;
    height: 650px;
    float: left;
    overflow-y: scroll;
  }

  .employee {
    background-color: #FFFFFF;
    min-height: 70px;
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    padding: 15px 10px;
    margin: 8px 0px;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #E2E2E2;
  }

  .employee .row {
    margin: 0px;
  }

  .employee .col-12 {
    padding: 0px 16px;
  }

  .employee .title {
    color: #5C5D5D;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    text-align: left;
  }

  .employee .body {
    color: #5C5D5D;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    display: block;
    text-align: left;
  }

  .employee .date {
    color: #5C5D5D;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    padding-top: 20px;
  }
